import { createRef, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { 
  IoCloseOutline, IoStar, IoStarOutline, IoCreateOutline, IoRemoveCircleOutline, IoCheckmark, 
  IoListOutline
} from "react-icons/io5";
import { 
  CabAutocomplete, CabButton, CabControlLabel, CabExecPicker, CabIcon, CabInput, CabSwitch, CabTooltip,
  CabSpinner, CabAvatar,
  CabModal,
  CabTextInput
} from "@CabComponents";
import { emailRegex } from "../../../constants";
import colors from "../../../colors";
import {
  Leader, MeetingLeader, MeetingLeaderUpdate, ParticipantAutocompleteOption, PrivateExternalParticipant,
  PrivateExternalParticipantCreate, PrivateExternalParticipantUpdate,
} from "../../../store";
import { useMountEffect } from "../../../utils/hooks";
import { getLeaderIconSrc } from "../../../utils/leaderUtils";
import { ParsedNameAndEmail, parseEmailAddresses } from "../../../utils/inputUtils";
import { getLocalTimeZoneNameOrNull } from "../../../utils/scheduleUtils";


export interface AttendeeListProps {
  attendees: PrivateExternalParticipant[];
  meetingLeaders?: MeetingLeader[];
  onAddAttendee: (participant: Omit<PrivateExternalParticipantCreate, 'meeting'>) => Promise<void>;
  onUpdateAttendee: (participant: PrivateExternalParticipantUpdate) => Promise<void>;
  onDeleteAttendee: (id: number) => void;
  participantAutocompleteOptions: ParticipantAutocompleteOption[];
  fetchAutocompleteOptions: (value: string) => void;
  isMdDown: boolean;
  hasAttendeeListOptions: boolean;
  onAddLeader?: (id: number) => void;
  onUpdateLeader?: (leader: MeetingLeaderUpdate, leaderId: number) => Promise<void>;
  onRemoveLeader?: (meetingLeaderId: number) => void;
  leaders?: Leader[];
  isAdditionalParticipantModal?: boolean;
  disableAdditionalParticipantsMessage?: string;
  meetingPreventConflicts?: boolean;
  meetingIsSaved: boolean;
}

type Participant = Pick<PrivateExternalParticipant | MeetingLeader,
'id'|'is_fetchable'|'prevent_conflicts'|'required'|'should_invite'|'view_calendar'>
& { email: string | null; iconSrc?: string | null; color?: string; name: string };

type ParticipantUpdate = Omit<Participant, 'id'|'is_fetchable'> & { id: number };

interface AttendeeListItemProps {
  attendee: Participant;
  onDeleteAttendee?: (attendeeId: number) => Promise<void> | void;
  onUpdateAttendee: (attendee: ParticipantUpdate) => Promise<void>;
  setEditingAttendee?: (attendeeId: number) => void;
  hideDeleteIcon?: boolean;
  isAdditionalParticipantModal?: boolean;
  hideCalendarAccessToggles?: boolean;
  meetingPreventConflicts?: boolean;
  loading?: boolean;
  meetingIsSaved: boolean;
}

interface FormInput {
  id?: number;
  email: string;
  name?: string;
  required: boolean;
  inviteToMeeting: boolean;
  showCalendar: boolean;
  preventConflicts: boolean;
}

interface AddEditAttendeeFormProps {
  defaultValues: FormInput, onSubmit: SubmitHandler<FormInput>, loading: boolean, isMdDown: boolean,
  submitButtonText: React.ReactNode, resetAfterSubmit?: boolean, onCancel?: () => void, 
  participantAutocompleteOptions: ParticipantAutocompleteOption[], fetchAutocompleteOptions: (value: string) => void;
  isAdditionalParticipantModal?: boolean; disabled?: boolean; allOtherAttendees: Participant[];
}

const AddEditAttendeeForm = ({
  defaultValues, onSubmit, loading, participantAutocompleteOptions, submitButtonText,
  resetAfterSubmit, onCancel, fetchAutocompleteOptions, isMdDown, isAdditionalParticipantModal, disabled,
  allOtherAttendees,
}: AddEditAttendeeFormProps) => {
  const { 
    control, handleSubmit: formSubmit, reset, setFocus, setValue, getValues, clearErrors
  } = useForm<FormInput>({ defaultValues });

  const [name, setName] = useState(getValues('name'));
  const [email, setEmail] = useState(getValues('email'));

  const attendeeRegex = /(?<name>[^()]*?) *\((?<email>[^()@]*@[^()@]*\.[^()@]*)\)/;

  useMountEffect(() => {
    setFocus('name');
  });

  useEffect(() => {
    setName(getValues('name'));
    setEmail(getValues('email'));
  }, [getValues]);

  const handleChangeInput = (param: 'name' | 'email', value: string) => {
    const match = attendeeRegex.exec(value);
    if (match && match.groups) {
      setName(match.groups.name);
      setEmail(match.groups.email);
      setValue('name', match.groups.name || ' '); // putting a single space which gets trimmed
      if (!isAdditionalParticipantModal) {
        setValue('email', match.groups.email);
      }
      clearErrors('email');
    } else {
      setValue(param, value);
    }
  };

  const handleSubmit = formSubmit((...args) => {
    onSubmit(...args);
    if (resetAfterSubmit) {
      reset();
      setFocus('email');
    }
  });

  const validateUniqueEmail = useCallback((eml: string) => {
    if (allOtherAttendees.map(a => (a.email || "").toLowerCase()).includes(eml.toLowerCase())) {
      return 'Emails must be unique';
    }
    return true;
  }, [allOtherAttendees]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={5}>
            <Controller
              name="name"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => isAdditionalParticipantModal ? (
                <CabInput
                  {...field}
                  onChange={onChange}
                  size="small"
                  disabled={loading || disabled}
                  sx={{ minWidth: isMdDown ? '150px' : '190px' }}
                  placeholder="Name (optional)"
                />
              ) : (
                <CabAutocomplete<string, never>
                  {...field}
                  inputRef={ref}
                  onChange={(v) => handleChangeInput('name', v ? (Array.isArray(v) ? v[0] : v) : '')}
                  freeSolo
                  onFocus={() => fetchAutocompleteOptions(name || '')}
                  autoHighlight
                  autoSelect
                  clearable
                  disabled={loading || disabled}
                  sx={{ minWidth: isMdDown ? '150px' : '190px' }}
                  onInputChange={(v) => {
                    fetchAutocompleteOptions(v);
                    setName(v);
                  }}
                  placeholder="Name (optional)"
                  options={name ? [{label: name}, ...participantAutocompleteOptions].map((participant, index) => {
                    return { value: participant.label, label: participant.label, key: index };
                  }) : participantAutocompleteOptions.map((participant, index) => {
                    return { value: participant.label, label: participant.label, key: index };
                  })
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            {isAdditionalParticipantModal ? (
              <Typography width={'%100%'} minWidth={isMdDown ? '150px' : '190px'} marginTop={1}>
                {email}
              </Typography>
            ) : (
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: emailRegex,
                  validate: validateUniqueEmail,
                }}
                render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                  <>
                    <CabAutocomplete<string, never>
                      {...field}
                      inputRef={ref}
                      onChange={(v) => handleChangeInput('email', v ? (Array.isArray(v) ? v[0] : v) : '')}
                      freeSolo
                      onFocus={() => fetchAutocompleteOptions(email || '')}
                      autoHighlight
                      autoSelect
                      clearable
                      disabled={loading || disabled}
                      sx={{ minWidth: isMdDown ? '150px' : '190px' }}
                      onInputChange={(v) => {
                        fetchAutocompleteOptions(v);
                        setEmail(v);
                      }}
                      placeholder="Email"
                      options={email ? [{label: email}, ...participantAutocompleteOptions].map((participant, index) => {
                        return { value: participant.label, label: participant.label, key: index };
                      }) : participantAutocompleteOptions.map((participant, index) => {
                        return { value: participant.label, label: participant.label, key: index };
                      })
                      }
                    />
                    <Typography color="error">
                      {error?.type === "required" ? "This field is required"
                        : error?.type === "pattern" ? "Please enter a valid email"
                          : error?.message || ''}
                    </Typography>
                  </>
                )}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="row" gap={0.5}>
              <CabButton
                type="submit"
                disabled={loading || disabled}
                sx={{ minWidth: 16, paddingTop: 1.2, paddingBottom: 1.2, paddingLeft: 1, paddingRight: 1, flex: 1 }}
              >
                {loading ? <CabSpinner scale={1} sx={{ width: 32 }} /> : submitButtonText}
              </CabButton>
              {onCancel && (
                <CabButton
                  disabled={loading || disabled}
                  sx={{ minWidth: 16, paddingTop: 1.2, paddingBottom: 1.2, paddingLeft: 1, paddingRight: 1, flex: 1 }}
                  onClick={onCancel}
                  buttonType="tertiary"
                >
                  <CabIcon Icon={IoCloseOutline} />
                </CabButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const AttendeeListItem = ({
  attendee, onDeleteAttendee, onUpdateAttendee, setEditingAttendee, hideDeleteIcon, 
  isAdditionalParticipantModal, hideCalendarAccessToggles, meetingPreventConflicts, loading, meetingIsSaved
}: AttendeeListItemProps): ReactElement => {
  const [propertyLoading, setPropertyLoading] = useState(false);
  const handleToggleProperty = (
    property: 'required'|'should_invite'|'view_calendar'|'prevent_conflicts'
  ) => {
    setPropertyLoading(true);
    onUpdateAttendee({
      ...attendee,
      [property]: !attendee[property],
    });
    setPropertyLoading(false);
  };

  return (
    <Box display='flex' flexDirection='column' width={'100%'} gap={1}>
      <Box display='flex' justifyContent={'space-between'} width={'100%'}>
        <Box display='flex' gap={.5} width={'30%'}>
          {(attendee.iconSrc || attendee.color) && (
            <CabAvatar
              src={attendee.iconSrc}
              color={attendee.color}
              name={attendee.name}
              size="small"
            />
          )}
          <Typography noWrap fontStyle={attendee.required ? 'unset' : 'italic'} width={'100%'} fontSize={15}>
            {attendee.name}
          </Typography>
        </Box>
        <CabTooltip placement="top-start" title={attendee.email}>
          <Typography noWrap fontStyle={attendee.required ? 'unset' : 'italic'} paddingLeft={1} 
            width={'50%'} paddingRight={1} fontSize={15}>
            {attendee.email}
          </Typography>
 
        </CabTooltip>
        <Box display="flex" flexDirection="row" gap={1} justifyContent={'flex-end'}>
          {(loading || propertyLoading) ? (
            <CabSpinner scale={0.8} />
          ) : (<>
            <CabTooltip title={attendee.required ? 'Mark as optional' : 'Mark as required'} wrapWithSpan>
              <CabIcon size="small" Icon={attendee.required ? IoStar : IoStarOutline}
                onClick={() => handleToggleProperty('required')} 
                sx={{color: attendee.required ? colors.greenPrimary : 'unset'}}
              />
            </CabTooltip>
            {setEditingAttendee && (
              <CabIcon size="small" Icon={IoCreateOutline} alt='edit' 
                onClick={() => setEditingAttendee(attendee.id)}
              />
            )}
            {!hideDeleteIcon && (
              <CabIcon
                size="small"
                Icon={IoRemoveCircleOutline}
                alt='remove'
                onClick={onDeleteAttendee ? () => onDeleteAttendee(attendee.id) : undefined}
              />
            )}
          </>)}
        </Box>
      </Box>
      {isAdditionalParticipantModal && (
        <Box display='flex' justifyContent={'space-between'} width={'100%'}>
          <CabControlLabel
            label='Invite to event'
            labelPlacement='end'
            labelGap={2}
            control={<CabSwitch
              checked={attendee.should_invite}
              disabled={loading || propertyLoading}
              onChange={() => handleToggleProperty('should_invite')}
            />}
          />
          <CabTooltip 
            title={hideCalendarAccessToggles ? meetingIsSaved ? 'You don\'t have access to this calendar'
              : "You can use additional settings for participants once the meeting is saved."  : ''} 
            wrapWithSpan
          >
            <CabControlLabel
              label='Show calendar'
              labelPlacement='end'
              labelGap={2}
              control={<CabSwitch
                checked={attendee.view_calendar}
                disabled={hideCalendarAccessToggles || loading || propertyLoading}
                onChange={() => handleToggleProperty('view_calendar')}
              />}
            />
          </CabTooltip>
          <CabTooltip 
            title={hideCalendarAccessToggles ? meetingIsSaved ? 'You don\'t have access to this calendar'
              : "You can use additional settings for participants once the meeting is saved." 
              : !meetingPreventConflicts ? 'Prevent conflicts is turned off for this meeting' : ''} 
            wrapWithSpan
          >
            <CabControlLabel
              label='Prevent conflicts'
              labelPlacement='end'
              labelGap={2}
              control={<CabSwitch
                checked={attendee.prevent_conflicts}
                disabled={hideCalendarAccessToggles || !meetingPreventConflicts || loading || propertyLoading}
                onChange={() => handleToggleProperty('prevent_conflicts')}
              />}
            />
          </CabTooltip>
        </Box>
      )}
    </Box>
  );
};


const AttendeeList = ({
  attendees, onDeleteAttendee, onAddAttendee, onUpdateAttendee, meetingLeaders, leaders, onRemoveLeader, onAddLeader, 
  onUpdateLeader, participantAutocompleteOptions, fetchAutocompleteOptions, isMdDown, hasAttendeeListOptions,
  isAdditionalParticipantModal, disableAdditionalParticipantsMessage, meetingPreventConflicts, meetingIsSaved
}: AttendeeListProps): ReactElement => {
  const [addingAttendee, setAddingAttendee] = useState(false);
  const [editingAttendee, setEditingAttendee] = useState<number|null>(null);
  const [updatingAttendee, setUpdatingAttendee] = useState(false);
  const [bulkAddOpen, setBulkAddOpen] = useState(false);
  const [bulkAddText, setBulkAddText] = useState("");
  const [parsedAttendees, setParsedAttendees] = useState<ParsedNameAndEmail[]>([]);
  const csvImportRef = createRef<HTMLInputElement>();

  const bottomRef = useRef<null | HTMLDivElement>(null);

  const participantLeaders = meetingLeaders?.map(ml => ({
    ...ml,
    is_fetchable: ml.is_fetchable,
    meeting_leader_id: ml.id,
    leader_id: ml.leader,
    email: leaders?.find(l => l.id === ml.leader)?.email || '',
    name: `${ml.first_name} ${ml.last_name}`,
    color: ml.color,
    iconSrc: getLeaderIconSrc(ml),
  }));

  const leaderOptions = useMemo(() => {
    return leaders?.filter(l => !meetingLeaders?.find(ml => ml.leader === l.id))
      .map(leader => {
        return {
          value: leader.id,
          label: `${leader.first_name} ${leader.last_name}`,
          icon: <CabAvatar
            src={getLeaderIconSrc(leader)}
            color={leader.color}
            name={`${leader.first_name} ${leader.last_name}`}
            size="small"
          />
        };
      });
  }, [leaders, meetingLeaders]);

  const handleAddSubmit: SubmitHandler<FormInput> = async (data) => {
    setAddingAttendee(true);
    await onAddAttendee({
      email: data.email,
      name: data.name,
      calendar_access: null,
      required: data.required,
      should_invite: data.inviteToMeeting,
      timezone: getLocalTimeZoneNameOrNull()
    });
    setAddingAttendee(false);
  };

  const handleEditSubmit = async (data: FormInput) => {
    if (data.id) {
      const attendee = attendees.find(a => a.id === data.id);
      setUpdatingAttendee(true);
      await onUpdateAttendee({
        id: data.id,
        email: data.email,
        required: attendee?.required || false,
        should_invite: data.inviteToMeeting,
        view_calendar: data.showCalendar,
        prevent_conflicts: data.preventConflicts,
        name: data.name,
        calendar_access: attendee?.calendar_access || null
      });
      setUpdatingAttendee(false);
    }
    setEditingAttendee(null);
  };

  const handleUpdateMeetingLeader = async (leader: ParticipantUpdate) => {
    if (!onUpdateLeader) return;

    const ldr = participantLeaders?.find(l => l.meeting_leader_id === leader.id);
    if (!ldr?.meeting_leader_id) return;
    setUpdatingAttendee(true);
    await onUpdateLeader(
      { ...leader, id: ldr.meeting_leader_id, leader: ldr.leader_id },
      ldr.leader_id,
    );
    setUpdatingAttendee(false);
  };

  const handleUpdateAttendee = async (attendee: ParticipantUpdate) => {
    setUpdatingAttendee(true);
    await onUpdateAttendee(attendee);
    setUpdatingAttendee(false);
  };

  const tryParseBulkAdd = (input: string) => {
    const namesAndEmails = parseEmailAddresses(input);
    const dedupedNamesAndEmails: ParsedNameAndEmail[] = [];
    const seenEmails: {[email: string]: number} = {};
    let idx = 0;
    namesAndEmails.forEach(entry => {
      const existingIdx = seenEmails[entry.email];
      if (existingIdx != undefined) {
        // Email has already been - overwrite name if not already present
        if (dedupedNamesAndEmails[existingIdx] && !dedupedNamesAndEmails[existingIdx].name) {
          dedupedNamesAndEmails[existingIdx].name = entry.name;
        }
      } else {
        dedupedNamesAndEmails.push(entry);
        seenEmails[entry.email] = idx;
        idx += 1;
      }
    });
    setParsedAttendees(namesAndEmails);
  };

  const closeBulkAddModal = () => {
    setBulkAddOpen(false);
    setBulkAddText("");
    setParsedAttendees([]);
  };

  const addParsedAttendees = async () => {
    setAddingAttendee(true);
    const existingAttendeeEmails = attendees.map(a => a.email?.toLocaleLowerCase());
    parsedAttendees.forEach(attendee => {
      // Running synchronously to prevent old responses from overwriting newer ones
      if (!existingAttendeeEmails.includes(attendee.email)) {
        onAddAttendee({
          email: attendee.email,
          name: attendee.name || undefined,
          calendar_access: null,
          required: true,
          should_invite: true,
          timezone: getLocalTimeZoneNameOrNull()
        });
        existingAttendeeEmails.push(attendee.email);
      }
    });
    setAddingAttendee(false);
    closeBulkAddModal();
  };

  const handleImportCsvClick = (): void => {
    if (csvImportRef.current) {
      csvImportRef.current.click();
    }
  };

  return (
    <Box display='flex' width='100%' height='100%' gap={1} flexDirection='column'>
      {isAdditionalParticipantModal && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} height={'100%'} overflow={'clip'}>
          <Typography variant="h2"> Teammates </Typography>
          <Box maxHeight={'80%'} overflow={'auto'} sx={{
            direction: 'rtl',
            '::-webkit-scrollbar': {
              width: 4,
              height: 8,
              backgroundColor: 'none',
            },
            '::-webkit-scrollbar-thumb': {
              background: colors.black500,
              borderRadius: 2,
            }}}>
            <Box display='flex' flexDirection='column' marginTop={1} width="100%" gap={.75}
              sx={{overflowX: 'hidden', direction: 'ltr'}}>
              {(participantLeaders || []).slice().reverse().map((leader, idx) => (
                <Box
                  key={leader.meeting_leader_id}
                  display="flex"
                  alignItems="center"
                  bgcolor={colors.black100}
                  padding={1}
                  borderRadius={.75}
                >
                  <AttendeeListItem
                    attendee={{ ...leader, id: leader.meeting_leader_id }}
                    onDeleteAttendee={onRemoveLeader}
                    onUpdateAttendee={handleUpdateMeetingLeader}
                    hideDeleteIcon={(participantLeaders || []).length <= 1}
                    isAdditionalParticipantModal={isAdditionalParticipantModal}
                    hideCalendarAccessToggles={!leader.is_fetchable}
                    meetingPreventConflicts={meetingPreventConflicts}
                    meetingIsSaved={meetingIsSaved}
                  />
                </Box>
              ))}
              <div ref={bottomRef} />
            </Box>
          </Box>
          {leaderOptions && onAddLeader && (
            <CabExecPicker<number>
              value={null}
              options={leaderOptions}
              onChange={v => onAddLeader(v)}
              placeholder={leaderOptions.length === 0
                ? 'There are no more teammates available to add'
                : 'Select a Teammate to Add'}
              disabled={leaderOptions.length === 0}
            />
          )}
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} height={'100%'} overflow={'clip'}>
        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between"}} marginBottom={0.5}>
          <Typography variant="h2"> 
            {isAdditionalParticipantModal ? 'Additional People & Calendars' : 'Attendee List'} 
          </Typography>
          <CabButton 
            buttonType="tertiary" 
            onClick={() => setBulkAddOpen(true)} 
            icon={<CabIcon alt="Edit" Icon={IoListOutline} />}
            sx={{border: 0, paddingLeft: 0, paddingRight: 0, paddingTop: '2px', ":hover": {border: 0}}}
          >
            Bulk Add 
          </CabButton>
        </Box>
        
        {disableAdditionalParticipantsMessage && (
          <Typography variant="body2"> 
            {disableAdditionalParticipantsMessage}
          </Typography>
        )}
        {hasAttendeeListOptions && (
          <AddEditAttendeeForm
            defaultValues={{
              email: '', name: '', required: false, inviteToMeeting: true, showCalendar: false, preventConflicts: false
            }}
            onSubmit={handleAddSubmit}
            submitButtonText="Add"
            loading={addingAttendee}
            participantAutocompleteOptions={participantAutocompleteOptions}
            fetchAutocompleteOptions={fetchAutocompleteOptions}
            resetAfterSubmit
            isMdDown={isMdDown}
            disabled={!!disableAdditionalParticipantsMessage}
            allOtherAttendees={attendees}
          />
        )}

        <Box maxHeight={'80%'} overflow={'auto'} sx={{
          direction: 'rtl',
          '::-webkit-scrollbar': {
            width: 4,
            height: 8,
            backgroundColor: 'none',
          },
          '::-webkit-scrollbar-thumb': {
            background: colors.black500,
            borderRadius: 2,
          }}}>
          <Box display='flex' flexDirection='column' marginTop={1} width="100%" gap={.75}
            sx={{overflowX: 'hidden', direction: 'ltr'}}>
            {attendees.slice().reverse().map((attendee, idx) => (
              <Box
                key={attendee.id}
                display="flex"
                alignItems="center"
                padding={1}
                bgcolor={colors.black100}
                borderRadius={.75}
              >
                {editingAttendee === attendee.id ? (
                  <Box marginLeft={1}>
                    <AddEditAttendeeForm
                      defaultValues={{
                        id: attendee.id, email: attendee.email || "", name: attendee.name,
                        required: attendee.required, inviteToMeeting: attendee.should_invite,
                        showCalendar: attendee.view_calendar, preventConflicts: attendee.prevent_conflicts,
                      }}
                      onSubmit={handleEditSubmit}
                      onCancel={() => setEditingAttendee(null)}
                      submitButtonText={<CabIcon Icon={IoCheckmark} sx={{ fontSize: 20 }} />}
                      loading={updatingAttendee}
                      participantAutocompleteOptions={participantAutocompleteOptions}
                      fetchAutocompleteOptions={fetchAutocompleteOptions}
                      isMdDown={isMdDown}
                      isAdditionalParticipantModal={isAdditionalParticipantModal || false}
                      disabled={!!disableAdditionalParticipantsMessage}
                      allOtherAttendees={attendees.filter(a => a.id !== attendee.id)}
                    />
                  </Box>
                ) : (
                  <AttendeeListItem
                    attendee={{
                      ...attendee,
                      view_calendar: !!(attendee.view_calendar && attendee.calendar_access),
                      prevent_conflicts: !!(attendee.prevent_conflicts && attendee.calendar_access),
                    }}
                    onDeleteAttendee={onDeleteAttendee}
                    onUpdateAttendee={handleUpdateAttendee}
                    setEditingAttendee={setEditingAttendee}
                    isAdditionalParticipantModal={isAdditionalParticipantModal}
                    hideCalendarAccessToggles={!attendee.is_fetchable}
                    meetingPreventConflicts={meetingPreventConflicts}
                    loading={attendee.meeting > 0 && attendee.id < 0}
                    meetingIsSaved={meetingIsSaved}
                  />
                )}
              </Box>
            ))}
            <div ref={bottomRef} />
          </Box>
        </Box>
      </Box>
      <CabModal
        open={bulkAddOpen}
        onClose={closeBulkAddModal}
        closeOnBackdropClick
        actionButtons={<>
          <CabButton buttonType="secondary" onClick={closeBulkAddModal}>
            Cancel
          </CabButton>
          <CabButton onClick={handleImportCsvClick}>
            Import CSV
            <input
              hidden
              type="file"
              accept={".csv"}
              ref={csvImportRef}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  const file: File = e.target.files[0];
                  file.text().then(res => {
                    setBulkAddText(res);
                    tryParseBulkAdd(res);
                  });
                }
              }}
            />
          </CabButton>
          <CabButton disabled={parsedAttendees.length === 0 || addingAttendee} onClick={addParsedAttendees}>
            {addingAttendee ? "Adding..." : "Add People"}
          </CabButton>
        </>}
        title={"Add Meeting Attendees"}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: "50%",
            overflowX: "hidden"
          }
        }}
      >
        <Typography variant="body1" marginBottom={1}>
          Import a CSV or paste a list of names and email addresses below. 
          Separate each person with commas, semicolons, or new lines.
        </Typography>
        <CabTextInput 
          fullWidth 
          multiline 
          minRows={4} 
          maxRows={10}
          value={bulkAddText}
          onChange={(e) => {
            const newBulkAddText = e.target.value;
            setBulkAddText(newBulkAddText);
            tryParseBulkAdd(newBulkAddText);
          }}
        />
        {parsedAttendees.length > 0 && <>
          <Typography variant="h5" marginTop={2}>
            Preview Attendees
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parsedAttendees.map(entry => (
                  <TableRow key={entry.email}>
                    <TableCell>{entry.name ? entry.name : "-"}</TableCell>
                    <TableCell>{entry.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        }
      </CabModal>
    </Box>
  );
};

export default AttendeeList;
